.form {
  position: relative;
  background: transparent;
  width: 100%;
  margin-bottom: 15px;
}

.section {
  padding: 15px;
}
.commonAttributes {
  background: #e3ddff;
}

.picture img {
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  display: block;
  max-height: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
}
.miniature img {
  border-radius: 5px;
  border: 1px solid #ddd;
  display: block;
  max-width: 120px;
}
.inputBox input ~ span {
  background: #f9f9f9;
}
.inputBox input,
.inputBox div[class*="inputWrapper"],
.inputBox button {
  height: 38px;
  min-width: 254px;
}
.inputBox button {
  min-width: 65px;
  background-position-x: calc(100% - 5px);
}
.formSection h5 {
  width: 100%;
  font-size: 0.81rem;
  color: #738992;
  margin-top: 10px;
  padding-top: 8px;
  border-top: 1px solid #eee;
}
/* preselected attribute */
.preselected {
  display: flex;
  align-items: center;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-bottom: 15px;
}
.fieldsetLabel {
  width: fit-content;
  width: -moz-fit-content;
  color: #8b9396;
  font-size: 0.81rem;
  margin-right: 10px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: capitalize;
}
.attribute {
  box-shadow: none;
  display: inline-flex;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.81rem;
  height: 28px;
  line-height: 25px;
  padding: 0 3px;
  background: #ddd;
  align-items: center;
  color: #333;
  border: 2px solid #ddd;
  border-radius: 5px;
  white-space: nowrap;
}
.attribute img {
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.searchItem {
  height: 50px;
  border-radius: 8px;
}

.readyOnlyInput {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ddd;
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  padding: 0 5px;
  cursor: not-allowed;
  height: 38px;
}
