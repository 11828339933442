.manufacturerSelector {
  max-height: 26px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.manufacturerSelectorInput {
  z-index: 1;
}

.errorsBoard {
  border-top: 1px solid var(--grey50);
}
